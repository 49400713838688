
<template>
    <div>
      <v-sheet class="supplier" id="supplier">
        <v-row>
          <v-col md="12">
            <v-row>
      
             
              <v-col md="7" class="my-auto py-0 d-flex align-center">
                <h1 class="custom-header-blue-text m-0 d-flex me-4">
                  <span>{{singleemployee.first_name}}</span>
                </h1>
                <span class="salary-select-employee" v-if="false">
                  <AutoCompleteInput
                    hide-details
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    :items="employees"
                    id="segment"
                    class="custom-font m-0"
                    item-text="first_name"
                    v-model="serach_employee"
                    @change="searchEmployee"
                    item-value="id"
                    placeholder="Select Employee"
                  >
                  </AutoCompleteInput>
                </span>
                <div class="d-flex">
                 
                  <span class="me-2" style="font-weight: bold; font-size: 16px">{{monthName}} ({{salary.salary_year}})</span
                  >
        
  
                 </div>
                <p class="mb-0 ms-8">
                  <span class="me-2" style="font-weight: bold; font-size: 16px">MANDAY:</span
                  ><span>
                    <span v-if="monthDiff > 3" style="font-weight: bold">{{formatMoneys(singleemployee.after_probs_salary*12/312)}}</span>
                    <span v-else style="font-weight: bold">{{formatMoneys(singleemployee.during_probs_salary*12/312)}}</span>
                  </span>
                </p>
              </v-col>
            
              <v-col md="5" class="text-right py-0">
                <div class="d-flex align-center justify-end">
                  <div class="text-h5 font-weight-medium" style="color: green">{{formatedateTime(singleemployee.monthly_salary_date)}}</div>
                  <template v-if="type == 0">
                    
                        <div class="text-center mx-2" span="2">
                          <div class="">
                            <v-menu offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  outlined
                                  class="py-2"
                                  small
                                  :color="getStatusData(salary.status, 'color')"
                                  v-bind="attrs"
                                  v-on="on"
                                  style="font-size: 12px; font-weight: bold"
                                >
                                  <v-icon size="16">{{ getStatusData(salary.status, "icon") }}</v-icon>
                                  <span class="px-1">{{ getStatusData(salary.status, "title") }}</span>
                                  <v-icon size="16">mdi-chevron-down</v-icon>
                                </v-btn>
                              </template>
                              <v-card width="250">
                                <v-list dense class="py-0" v-if="salary.status != 4">
                                  <template v-for="(item, index) in statusList">
                                    <v-list-item 
                                      @click="currentSelectTitle(item, salary)"
                                      :key="index"
                                      :class="{
                                        'grey lighten-3':
                                          getStatusData(salary.status, 'title') == item?.title,
                                      }"
                                    >
                                      <v-list-item-icon class="my-1 mr-2">
                                        <v-icon :color="item.color">{{ item.icon }}</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>{{ item?.title  }}</v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-divider
                                      :key="index"
                                      v-if="index != -1"
                                      class="my-0"
                                      style="width: 100%"
                                    ></v-divider>
                                  </template>
                                </v-list>
                              </v-card>
                            </v-menu>
                          </div>
                        </div>
                      </template>

                      <template v-else>
                        <div class="text-center mx-2" span="2">
                          <template>ERGGD</template>
                        </div>
                      </template>
     
                  <v-btn
                    :disabled="pageLoading || this.salary.status == 1"
                    :loading="pageLoading"
                    class="white--text mx-2"
                    color="red darken-4"
                    tile
                  >
                    <v-icon>mdi-file-pdf-box</v-icon>
                  </v-btn>
                
                  
                  <v-btn
                    class="mr-2"
                    v-if="stepperHeader.value > 1"
                    :disabled="pageLoading"
                    depressed
                    tile
                    v-on:click="stepperHeader.value--"
                  >
                    Previous
                  </v-btn>
                  <v-btn
                  :disabled="pageLoading || this.salary.status == 4"
                  :loading="pageLoading"
                  class="white--text mx-2"
                  depressed
                  color="blue darken-4"
                  tile
                  @click="routeToUpdate(salary.id)"
                >
                  {{ stepperHeader.value === stepperHeader.header?.length ? "Save" : "Edit" }}
                </v-btn>
                  
                  <v-btn class="ml-2" :disabled="pageLoading" depressed tile v-on:click="goBack()">
                    Back
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="12" class="mt-0 py-0">
            <v-row class="py-3" style="background-color: rgb(240, 248, 255)">
              <!-- <div class="col-md-4 col py-0">
                <p class="mb-1">
                  <span class="me-2" style="font-weight: bold">Employee Name:</span
                  ><span><span style="font-weight: 600">TEE LI SIN </span></span>
                </p>
              </div> -->
              <div class="col-md-4 col py-0">
                <p class="mb-1">
                  <span class="me-2" style="font-weight: bold">NRIC:</span
                  ><span><span>{{singleemployee.passport}}</span></span>
                </p>
              </div>
              <div class="col-md-4 col py-0">
                <p class="mb-0">
                  <span class="me-2" style="font-weight: bold">Hire Date: </span
                  ><span><span>{{formatedateTime(singleemployee.joining_date)}} </span></span>
                </p>
              </div>
              <div class="col-md-4 col py-0">
                <p class="mb-0">
                  <span class="me-2" style="font-weight: bold">Job Title: </span
                  ><span><span class="text-capitalize">{{singleemployee.role_name}}</span></span>
                </p>
              </div>
              <div class="col-md-8 col py-0">
                <p class="mb-0">
                  <span class="me-2" style="font-weight: bold">Mode Of Payment:</span
                  ><span><span>{{singleemployee.salary_mode}} </span></span>
                </p>
              </div>
            </v-row>
          </v-col>
         
          <v-col md="12" class="mt-0 pt-0" style="border-right: 1px solid #d8dbdd">
            <v-form
              ref="firstStep"
              v-model="firstStepValid"
              lazy-validation
              v-on:submit.stop.prevent="updateOrCreate"
            >
            <v-tabs
                v-model="customerTab"
                background-color="transparent"
          color="blue darken-4"
          class="customtabs w-100"
          
              >
              <v-tab href="#overview"   style="width: 100%"  icons-and-text>             <v-icon :size="18" class="me-1">mdi-information-outline</v-icon>OVERVIEW </v-tab>
              <v-tab href="#log"   style="width: 100%"><v-icon :size="18" class="me-1">mdi-history</v-icon> Logs </v-tab>
              <v-tab href="#status-log"   style="width: 100%"><v-icon :size="18" class="me-1">mdi-history</v-icon> Status Change </v-tab>
              <v-tabs-items v-model="customerTab" style="height: 65vh; overflow-y: auto">
                <v-tab-item value="overview">
                  <v-row>
                      <v-col md="7" class="">
                        <table class="grid table table-bordered table-sortable">
                          <thead>
                            <tr>
                              <th class="text-center" colspan="3">
                                <label><span style="font-weight: 600">EARNINGS</span></label>
                              </th>
                              <th class="text-center" colspan="3">
                                <label><span style="font-weight: 600">DEDUCTIONS</span></label>
                              </th>
                            </tr>
                            <tr>
                              <th class="text-center">
                                <label><span style="font-weight: 600">DESCRIPTION</span></label>
                              </th>
                              <th class="text-center" width="8%">
                                <label class="btx-label"><span style="font-weight: 600">QTY</span></label>
                              </th>
                              <th width="20%" class="text-center">
                                <label class="btx-label"
                                  ><span style="font-weight: 600">AMOUNT</span></label
                                >
                              </th>
                              <th class="text-center">
                                <label><span style="font-weight: 600">DESCRIPTION</span></label>
                              </th>
                              <th width="8%" class="text-center">
                                <label class="btx-label"><span style="font-weight: 600">QTY</span></label>
                              </th>
                              <th width="20%" class="text-center">
                                <label class="btx-label"
                                  ><span style="font-weight: 600">AMOUNT</span></label
                                >
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style="vertical-align: middle">
                                <label class="btx-label my-auto">Basic Pay</label>
                              </td>
                              <td>
                                  <label class="btx-label">{{formatMoneys(salary.qty)}}</label>

                            
                              </td>
                              <td style="vertical-align: middle">
                                <!-- <QuantityInput
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            id="category_list"
                            v-model="salary.qty"
                            :hideTopMargin="true"
                          ></QuantityInput> -->
                          <template >
                            <label class="btx-label">{{formatMoneys(salary.basic_salary)}}</label>
        
                          </template>
                         
                              </td>
                              <td style="vertical-align: middle">
                                <label class="btx-label">EMPLOYEE EPF</label>
                              </td>
                              <td style="vertical-align: middle">
                                <label class="btx-label">(MYR)</label>
                              </td>
                              <td>
                              
                                <label class="btx-label">{{formatMoneys(salary.epf)}}</label>

                              </td>
                            </tr>
                            <tr>
                              <td style="vertical-align: middle">
                                <label class="btx-label">Paid Leave</label>
                              </td>
                              <td>
                                
                                <label class="btx-label">{{formatMoneys(salary.paid_leave_qty)}}</label>

                              </td>
                              <td>
                                
                                <label class="btx-label">{{formatMoneys(salary.paid_leave)}}</label>

                              </td>
                              <td style="vertical-align: middle">
                                <label class="btx-label">SOCSO</label>
                              </td>
                              <td>
                              
                                <label class="btx-label">{{formatMoneys(salary.socso_qty)}}</label>

                              </td>
                              <td>
                                  <label class="btx-label">{{formatMoneys(salary.socso)}}</label>

                            
                              </td>
                            </tr>
                            <tr>
                              <td style="vertical-align: middle">
                                <label class="btx-label">Incentive</label>
                              </td>
                              <td>
                              
                                <label class="btx-label">{{formatMoneys(salary.incentive_qty)}}</label>

                              </td>
                              <td>
                              
                                <label class="btx-label">{{formatMoneys(salary.incentive)}}</label>

                              </td>
                              <td style="vertical-align: middle">
                                <label class="btx-label">SIP</label>
                              </td>
                              <td>
                                  <label class="btx-label">{{formatMoneys(salary.sip_qty)}}</label>

                              
                              </td>
                              <td>
                                  <label class="btx-label">{{formatMoneys(salary.sip)}}</label>

                                
                              </td>
                            </tr>
                            <tr>
                              <td style="vertical-align: middle">
                                <label class="btx-label">Over Time</label>
                              </td>
                              <td>
                                  <label class="btx-label">{{formatMoneys(salary.overtime_qty)}}</label>

                                
                              </td>
                              <td>
                                  <label class="btx-label">{{formatMoneys(salary.overtime)}}</label>

                            
                              </td>
                              <td style="vertical-align: middle">
                                <label class="btx-label"> Unpaid leave</label>
                              </td>
                              <td>
                                  <label class="btx-label">{{formatMoneys(salary.unpaid_qty)}}</label>

                                
                              </td>
                              <td>
                                  <label class="btx-label">{{formatMoneys(salary.unpaid)}}</label>

                                
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td ><label class="btx-label">Deduction wm</label></td>
                              <td></td>
                              <td><label class="btx-label">{{singleemployee.machine}}</label></td>
                            </tr>
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td style="vertical-align: middle"><label class="btx-label">Advance</label></td>
                              <td></td>
                              <td>
                                  <label class="btx-label">{{formatMoneys(salary.advance)}}</label>

                              
                                </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td style="vertical-align: middle"> <label class="btx-label">Deduction</label></td>
                              <td></td>
                              <td>
                                  <label class="btx-label">{{formatMoneys(salary.deduction)}}</label>

                                
                                
                              </td>
                            </tr>
                            <tr>
                              <td class="text-end" colspan="2" style="vertical-align: middle">
                                <label class="btx-label" style="font-weight: bold">
                                  Gross Earnings (MYR)</label
                                >
                              </td>
                              <td style="vertical-align: middle">
                                <label class="btx-label" style="font-weight: bold">{{formatMoneys(gross_earnings)}}</label>
                              </td>
                              <td class="text-end" colspan="2">
                                <label class="btx-label" style="font-weight: bold"
                                  >Gross Deductions (MYR)</label
                                >
                              </td>
                              <td>
                                <label style="font-weight: bold" class="btx-label">{{formatMoneys(gross_deductions)}}</label>
                              </td>
                            </tr>
                            <tr>
                              <td class="text-end" colspan="5">
                                <label class="btx-label custom-header-blue-text" style="font-weight: bold;font-size: 18px">Net Income (MYR)</label>
                              </td>
                              <td>
                                <label
                                  style="font-weight: bold; font-size: 18px"
                                  class="btx-label custom-header-blue-text"
                                  >{{formatMoneys(gross_earnings-gross_deductions)}}</label
                                >
                              </td>
                            </tr>
                            <tr>
                              <td colspan="6">&nbsp;</td>
                            </tr>
                            <tr>
                              <td style="vertical-align: middle">
                                <label class="btx-label" style="font-weight: bold">EMPLOYER'S EPF</label>
                              </td>
                              <td style="vertical-align: middle">
                                <label class="btx-label">(MYR)</label>
                              </td>
                              <td>
                                  <label class="btx-label">{{formatMoneys(salary.employee_epf)}}</label>

                              
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td style="vertical-align: middle">
                                <label style="font-weight: bold" class="btx-label">EMPLOYER'S SOCSO</label>
                              </td>
                              <td>
                                <label class="btx-label">(MYR)</label>
                              </td>
                              <td>
                                  <label class="btx-label">{{formatMoneys(salary.employee_socso)}}</label>

                            
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>
                                <label style="font-weight: bold" class="btx-label">EMPLOYER'S EIS</label>
                              </td>
                              <td>
                                <label class="btx-label">(MYR)</label>
                              </td>
                              <td>
                                  <label class="btx-label">{{formatMoneys(salary.employee_eis)}}</label>

                              
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </v-col>
                      <v-col md="5" class="">
                        <table width="100%">
                          <tr>
                            <td style="vertical-align: top; padding: 0px !important">
                              <table
                                width="40%"
                                class="grid auto-data-table table table-bordered table-sortable"
                              >
                                <thead>
                                  <tr>
                                    <th style="vertical-align: top" class="text-center" colspan="2">
                                      Auto data
                                    </th>
                                  </tr>
                                  <tr>
                                    <th>&nbsp;</th>
                                    <th>&nbsp;</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td style="vertical-align: middle">
                                      <label class="btx-label">incentive</label>
                                    </td>
                                    <td style="vertical-align: middle">
                                      <label class="btx-label">{{formatMoneys(salary.incentive)}}</label>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style="vertical-align: middle">
                                      <label class="btx-label">over time</label>
                                    </td>
                                    <td style="vertical-align: middle">
                                      <label class="btx-label">{{formatMoneys(salary.overtime)}}</label>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style="vertical-align: middle">
                                      <label class="btx-label"
                                        >Unpaid leave
                                        <v-tooltip top max-width="250">
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-icon size="20" v-bind="attrs" v-on="on" small>info</v-icon>
                                          </template>
                                          <span
                                            >it can be days or hors. on click show detailed data in pop
                                            up.</span
                                          >
                                        </v-tooltip>
                                      </label>
                                    </td>
                                    <td style="vertical-align: middle">
                                      <label class="btx-label">({{formatMoneys((this.salary.basic_salary*12/312)*salary.unpaid_qty)}} ) {{(salary.unpaid_qty)}}day</label>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style="vertical-align: middle">
                                      <label class="btx-label">machine</label>
                                    </td>
                                    <td style="vertical-align: middle">
                                      <label class="btx-label">{{formatMoneys(salary.machine)}}</label>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style="vertical-align: middle">
                                      <label class="btx-label"
                                        >advance
                                        <v-tooltip top max-width="250">
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-icon size="20" v-bind="attrs" v-on="on" small>info</v-icon>
                                          </template>
                                          <span
                                            >show data from app if have any advance ( 5th of the month).
                                            show details in pop up</span
                                          >
                                        </v-tooltip>
                                      </label>
                                    </td>
                                    <td style="vertical-align: middle">
                                      <label class="btx-label">{{formatMoneys(salary.advance)}}</label>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style="vertical-align: middle">
                                      <label class="btx-label"
                                        >deduction
                                        <v-tooltip top max-width="250">
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-icon size="20" v-bind="attrs" v-on="on" small>info</v-icon>
                                          </template>
                                          <span>show total of app as calendar month . show details</span>
                                        </v-tooltip>
                                      </label>
                                    </td>
                                    <td style="vertical-align: middle">
                                      <label class="btx-label">{{formatMoneys(salary.deduction)}}</label>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td style="border-left: 1px solid #000" v-if="singleemployee?.loan?.loan_rel?.length" valign="top">
                              <table 
                                width="60%"
                                class="grid auto-data-table table table-bordered table-sortable"
                              >
                                <thead>
                                  <tr>
                                    <th class="text-center" colspan="3">Loan</th>
                                  </tr>
                                  <tr>
                                    <th>MONTH</th>
                                    <th>EMI</th>
                                    <th>PAID</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(item, rowindex) in singleemployee?.loan?.loan_rel"
                  :key="rowindex">
                                    <td style="vertical-align: middle">
                                      <label class="btx-label">{{item.month_name}}</label>
                                    </td>
                                    <td style="vertical-align: middle">
                                      <label class="btx-label">{{item.emi_amount}}</label>
                                    </td>
                                    <td
                                     
                                      style="vertical-align: middle; background-color: #8ef18e"
                                    >
                                      <label class="btx-label">{{item.paid_amount}}</label>
                                    </td>
                                  </tr>
                                  
                                  <tr>
                                    <td style="vertical-align: middle">
                                      <label
                                        style="font-weight: bold; font-size: 18px"
                                        class="btx-label custom-header-blue-text"
                                        >{{singleemployee?.loan?.loan_amount}}</label
                                      >
                                    </td>
                                    <td style="vertical-align: middle">
                                      <label
                                        style="font-weight: bold; font-size: 18px"
                                        class="btx-label custom-header-blue-text"
                                        >{{singleemployee?.loan?.loan_amount}}</label
                                      >
                                    </td>
                                    <td style="vertical-align: middle">
                                      <label
                                        style="font-weight: bold; font-size: 18px"
                                        class="btx-label custom-header-blue-text"
                                        >{{ getTotal() }}</label
                                      >
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item value="log">
                    <SalaryLogTab></SalaryLogTab>
                  </v-tab-item>
                  <v-tab-item value="status-log">
                    <StatusTab></StatusTab>
                  </v-tab-item>
              </v-tabs-items>
              </v-tabs>
              
            </v-form>
          </v-col>
        </v-row>
        
        
        <Dialog :dialog="dialog">
          <template v-slot:title>
            <v-layout>
              <v-flex>Category</v-flex>
              <v-flex class="text-right">
                <v-btn
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  class="white--text"
                  depressed
                  color="blue darken-4"
                  tile
                  v-on:click="addCategory()"
                >
                  <v-icon left>mdi-plus</v-icon>
                  Add...
                </v-btn>
              </v-flex>
            </v-layout>
          </template>
          <template v-slot:body>
            <v-container fluid class="pt-0">
              <template v-for="(row, ind) in categorys">
                <QuantityInput
                  :key="ind"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  :id="`category-${ind}`"
                  placeholder="category"
                  v-model="row.name"
                  append-outer-icon="mdi-delete"
                  class="has-delete-outer"
                  v-on:click:append-outer="removeCategory(ind)"
                ></QuantityInput>
              </template>
            </v-container>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="pageLoading"
              :loading="pageLoading"
              class="white--text mr-2"
              depressed
              color="blue darken-4"
              tile
              v-on:click="updateCategories()"
            >
              Save
            </v-btn>
            <v-btn :disabled="pageLoading" depressed tile v-on:click="cancel()"> Cancel </v-btn>
          </template>
        </Dialog>
        <SalaryRemarkDialog :dialog="salaryRemarkDialog" @close="salaryRemarkDialog = false">
        </SalaryRemarkDialog>
        <Dialog :dialog="statusdialog" :dialogWidth="900">
          <template v-slot:title>Reason for Status Change</template>
          <template v-slot:body>
            <div class="col-md-12">
              <TextAreaInput
                v-model="reason"
                auto-grow
                :disabled="pageLoading"
                :loading="pageLoading"
                id="notes"
                v-on:keypress="(e) => manageLimit(e)"
                  v-on:paste="(e) => onPaste(e)"
                placeholder=""
              ></TextAreaInput>
            </div>
          </template>
          <template v-slot:action>
            <v-btn
              class="white--text mr-2"
              depressed
              color="blue darken-4"
              tile
              :disabled="excelLoading"
              :loading="excelLoading"
              v-on:click="saveReason(reason, status_new.value, status_id)"
            >
              Save
            </v-btn>
            <v-btn depressed tile color="red darken-4" @click="statusdialogs">
              <span style="color: white">Close</span>
            </v-btn>
          </template>
        </Dialog>
      </v-sheet>
    </div>
  </template>
  <script>
  import moment from "moment-timezone";
  
  import Dialog from "@/view/components/Dialog";
  import { toSafeInteger,find } from "lodash";
  import QuantityInput from "@/view/components/QuantityInput";
   import TextAreaInput from "@/view/components/TextAreaInput";
  //import FileUpload from "@/view/components/FileUpload";
  import { QUERY,PUT,POST } from "@/core/services/store/request.module";
  import { UPDATE_SUPPLIERCATEGORY } from "@/core/lib/common.lib";
  import AutoCompleteInput from "@/view/components/AutoCompleteInput";
  import PosMixin from "@/core/mixins/pos.mixin";
  import SalaryLogTab from "@/view/module/salary/SalaryLogTab";
  import StatusTab from "@/view/module/salary/StatusTab";

  import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
  import { CREATE_SALARY} from "@/core/lib/customer.lib";
  import { GET_SALARY } from "@/core/lib/customer.lib";
  
  import { UPDATE_SALARY } from "@/core/lib/customer.lib";
  import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
  import FileManagerMixin from "@/core/mixins/file-manager/file.manager.mixin";
  import SalaryRemarkDialog from "./SalaryRemarkDialog";
  import { head, isEmpty } from "lodash";
  
  export default {
    mixins: [FileManagerMixin,PosMixin],
    // name: "supplier-create",
    // title: "Create Supplier",
    title: "Details Sales Summry",
    data() {
      return {
        customerTab:'overview',
        statusdialog:false,
        reason: null,
        stepperHeader: {
          value: 1,
          stepper_h: [{ title: "Overview" }],
        },
        type:0,
        currentStatus: {
        icon: "mdi-arrow-up-bold-box-outline",
        title: "Draft",
        value: 1,
        color: "grey",
      },
      product_tabs: [
        {
          key: "details",
          title: "Details",
          icon: "mdi-information-outline",
          template: SalaryLogTab,
        }],
      statusList: [
        {
          icon: "mdi-arrow-up-bold-box-outline",
          title: "Draft",
          value: 1,
          color: "grey",
        },
        {
          icon: "mdi-checkbox-marked-circle",
          title: "Submit for approval",
          value: 2,
          color: "#F6BE00",
        },
        {
          icon: "mdi-progress-clock",
          title: "Approved",
          value: 3,
          color: "light-green",
        },
        {
          icon: "mdi-cancel",
          title: "paid ",
          value: 4,
          color: "green",
        },
        
      
      ],
        excelLoading: null,
        monthName:null,
        isEditable: false,
        salaryRemarkDialog: false,
        dialog: false,
        pageLoading: false,
        monthDiff:null,
        date2:null,
        loan:0,
        date1:null,
        employees: [],
        singleemployee:{},
        
        employee_data: {},
        customerMoreAction: [
        {
          title: "Mark as Pay",
          icon: "mdi-check-all",
          action: "approve",
        },
      ],
        month: [],
        months: [
          {
            name: "January",
            value: 1,
          },
          {
            name: "February",
            value: 2,
          },
          {
            name: "March",
            value: 3,
          },
          {
            name: "April",
            value: 4,
          },
          {
            name: "May",
            value: 5,
          },
          {
            name: "June",
            value: 6,
          },
          {
            name: "July",
            value: 7,
          },
          {
            name: "August",
            value: 8,
          },
          {
            name: "September",
            value: 9,
          },
          {
            name: "October",
            value: 10,
          },
          {
            name: "November",
            value: 11,
          },
          {
            name: "December",
            value: 12,
          },
        ],
        serach_employee: false,
  
        thirdStepValid: false,
        primary_checkbox: true,
        formValid: true,
        supplierId: null,
        defaut_person: {},
        codes: [],
        categorys: [],
        code: [],
        categoryList: [
          {
            text: "Computer & Electrionics",
            value: "eomputer & electrionics",
          },
          {
            text: "Manufacturing",
            value: "manufacturing",
          },
          {
            text: "Wholesale",
            value: "wholesale",
          },
          {
            text: "Distribution",
            value: "distribution",
          },
        ],
        psersonsArr: [],
        addressArr: {},
        secondStepValid: false,
        firstStepValid: false,
        salary: {
          qty:1,
          basic_pay_qty: null,
          basic_pay_amount: null,
          epf:null,
          paid_leave_qty:null,
          paid_leave:null,
          socso_qty:1,
          socso:null,
          incentive_qty:1,
          incentive:null,
          sip_qty:1,
          sip:null,
          overtime_qty:null,
          overtime:null,
          unpaid_qty:null,
          unpaid:0,
          employee_eis:0,
          employee_socso:0,
          employee_epf:0,
          basic_salary:0,
          machine:null,
          deduction:0,
          advance:0,
          salary_month:1,
          employee_id:this.$route.params.id,
          salary_year:null,
  
        },
        supplier: {
          title: null,
          first_name: null,
          last_name: null,
          contactPerson: [],
          address: {},
          description: null,
          category: null,
          company_contact_email_address: null,
          display_name: null,
          phone_number: null,
          company_email_address: null,
          category_input: [{ label: "Category", value: "" }],
          image: null,
          profile_logo: null,
        },
      };
    },
    watch: {
      "supplier.title": {
        handler(param) {
          this.defaut_person["title"] = param;
        },
      },
      "supplier.first_name": {
        handler(param) {
          this.defaut_person["first_name"] = param;
          console.log({ first_name: param });
        },
      },
      "supplier.last_name": {
        handler(param) {
          this.defaut_person["last_name"] = param;
        },
      },
      "supplier.display_name": {
        handler(param) {
          this.defaut_person["display_name"] = param;
        },
      },
      "supplier.company_phone_number": {
        handler(param) {
          this.defaut_person["company_phone_number"] = param;
        },
      },
      "supplier.company_email_address": {
        handler(param) {
          this.defaut_person["company_email_address"] = param;
        },
      },
    },
  
    components: {
      Dialog,
      QuantityInput,
      AutoCompleteInput,
      SalaryRemarkDialog,
      SalaryLogTab,
      StatusTab,
      TextAreaInput
      // PhoneInput,
    },
    methods: {
      getTotal(){
          this.product = this.singleemployee?.loan?.loan_rel

    		let sum = 0;
        console.log(this.pdetails)
    		this.product?.forEach((element) => {

    			sum = sum + Number(element.paid_amount);
    		});
        console.log(sum)
    		this.discount_total = Number(sum);
    		//  this.getDiscount();
    		return this.discount_total;

        },
      routeToDetail(id) {
      this.$router.push({
        name: "salaryDetailLogTab",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
      statusdialogs(){
        
      this.reason=null;
      this.statusdialog=false;
    },
      statusChange(status, id) {
      const _this = this;
      _this.pageLoading = true;
      this.$store
        .dispatch(QUERY, {
          url: `change-salary-status`,
          data: {
            status,
            id: id,
          },
        })
        .then((data) => {
          _this.reservation_status = data;
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Salary Status Updated Successfully." },
          ]);
        

          console.log(_this.reservation_sttus, "_this.customer_email");

          /*  _this.purchaseOrder.supplier.barcode = data.supplier.barcode
 console.log(_this.purchaseOrder.supplier.barcode)     */
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
          window.history.back();        });
    },
      saveReason(reason, status, id) {

      if (!this.reason) {
        this.$store.commit(SET_ERROR, [{ model: true, message: "The reason field is required" }]);
        return false;
      }

      const _this = this;
      _this.pageLoading = true;
      this.$store
        .dispatch(POST, {
          url: `create-salary-reasons`,
          data: {
            reason: this.reason,
            status,
            id: id,
          },
        })
        .then((data) => {
          _this.reservation_status = data;
         

          /*  _this.purchaseOrder.supplier.barcode = data.supplier.barcode
      console.log(_this.purchaseOrder.supplier.barcode)     */
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
          this.statusdialog = false;

          this.statusChange(status, id);

          //  this.getCustomers();
        });
    },
      currentSelectTitle(item, row) {
      //     this.currentStatus.title = item.title;
      // this.currentStatus.icon = item.icon;
      // this.currentStatus.color = item.color;
      this.statusdialog = true;
      this.status_new = item;
      this.status_id = row.id;
      console.log(item);
      console.log(row);

      // this.statusChange(item.value,row)
    },
    getStatusData(status, type) {
      let statusData = find(this.statusList, (row) => row.value == status);
      if(statusData && statusData[type]){
        return statusData[type];
      }
    },
      approve(id) {
        alert(id)
      this.$store
        .dispatch(PUT, {
          url: `salary-approve`,
          data: {
            id: id,
          },
        })
        .then((data) => {
          console.log(data);
          this.$router.replace({
            name: "salary",
            // params: { id: customer.id },
            query: { t: new Date().getTime() },
          });
          this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Salary  Payed Successfully." },
          ]);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },
  

      routeToUpdate(id) {
      this.$router.push({
        name: "salary-update",
        params: { id },
        query: { t: new Date().getTime(),  year: this.$route.query.year,month: this.$route.query.month },
      });
    },
      yearlist() {
        let currentYear = new Date().getFullYear();
        let startYear = 2022;
        this.yearList = [];
        for (let year = startYear; year <= currentYear; year++) {
          this.yearList.push(year);
        }
        console.log(this.yearList);
      },
      formatedateTime(param) {
        return moment(param).format("DD/MM/YYYY");
      },
      compareDates() {
        this.date1 = this.singleemployee.during_probs_date;
        this.date2 = this.singleemployee.after_probs_date;
        const date1 = new Date(this.date1);
        const date2 = new Date(this.date2);
  
        this.monthDiff = moment(date2).diff(moment(date1), "months", true);
        if(this.monthDiff > 3){
          this.salary.basic_salary = this.singleemployee.after_probs_salary
        }else{
          this.salary.basic_salary = this.singleemployee.during_probs_salary
  
        }
       
        this.salary.machine = this.singleemployee.machine
  
        console.log(this.monthDiff,'this.monthDiff')
        // this.monthDiff = date1.getMonth() - date2.getMonth();
  
        // if (date1.getTime() === date2.getTime()) {
        //     this.result = 'The dates are equal';
        // } else if (date1.getTime() > date2.getTime()) {
        //     this.result = 'Date 1 is later than date 2';
        // } else {
        //     this.result = 'Date 2 is later than date 1';
        // }
        // console.log(monthDiff,'result');
      },
      toggleEdit() {
        this.isEditable = !this.isEditable; // Toggle the edit mode
        if (!this.isEditable) {
          // If not in edit mode, update the original value
          this.salary.qty = this.editedQty;
        }
      },
      searchEmployee() {
        this.$store
          .dispatch(QUERY, {
            url: `/find-employee/${this.serach_employee}`,
          })
          .then((data) => {
            this.employee_data = data;
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.timerLoading = false;
          });
      },
      getEmployee() {
        const _this = this;
        this.$store
          .dispatch(QUERY, {
            url: "/get-employee",
          })
          .then((data) => {
            _this.employees = data;
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.timerLoading = false;
          });
      },
      findEmployee() {
        const _this = this;
        this.$store
          .dispatch(QUERY, {
            url: `employee/${this.$route.params.id}`,
          })
          .then((data) => {
            _this.singleemployee = data;
            this.compareDates();
  
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.timerLoading = false;
            this.isEditable=true;
          });
      },
      // updatePrimaryContact(){
      //   this.$nextTick(()=>{
      //     this.defaut_person['title'] = this.supplier.title;
      //     this.defaut_person['first_name'] = this.supplier.first_name;
      //     this.defaut_person['last_name'] = this.supplier.last_name;
      //     this.defaut_person['display_name'] = this.supplier.display_name;
      //   })
      // },
      cancel() {
        this.dialog = false;
      },
      removeCategory(index) {
        this.categorys.splice(index, 1);
        if (this.categorys.length <= 0) {
          this.addCategory();
        }
      },
      async updateCategories() {
        const _this = this;
        try {
          _this.pageLoading = true;
          for (let i = 0; i < this.categorys.length; i++) {
            // if (this.categories && this.categories[i] && this.categories[i].file) {
            // 	formData.append(`file[${i}][file]`, this.categories[i].file);
            // }
            if (this.categorys && this.categorys[i] && !this.categorys[i].name) {
              _this.$store.commit(SET_ERROR, [{ model: true, message: "This field is required" }]);
              return false;
            }
          }
          await UPDATE_SUPPLIERCATEGORY({ options: _this.categorys });
          _this.dialog = false;
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Category Updated Successfully" },
          ]);
        } catch (error) {
          _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
        } finally {
          _this.pageLoading = false;
        }
      },
      addCategory() {
        this.categorys.push({
          name: null,
        });
      },
      manageLimit(e) {
        if (this.supplier.description && this.supplier.description.length > 249) {
          e.preventDefault();
        }
      },
      onPaste(e) {
        let coppied = e.clipboardData.getData("Text");
        let preVal = this.supplier.description;
        let finalval = String(preVal) + String(coppied);
        if (Number(finalval.length) > 249) {
          let trimValue = finalval.substring(0, 250);
          this.supplier.description = trimValue;
          e.preventDefault();
        }
      },
      onPastes(e) {
        let coppied = e.clipboardData.getData("Text");
        let preVal = this.supplier.phone_number;
        let finalval = String(preVal) + String(coppied);
        if (Number(finalval.length) > 13) {
          let trimValue = finalval.substring(0, 14);
          this.supplier.phone_number = trimValue;
          e.preventDefault();
        }
      },
      onPastess(e) {
        let coppied = e.clipboardData.getData("Text");
        let preVal = this.supplier.company_phone_number;
        let finalval = String(preVal) + String(coppied);
        if (Number(finalval.length) > 13) {
          let trimValue = finalval.substring(0, 14);
          this.supplier.company_phone_number = trimValue;
          e.preventDefault();
        }
      },
      check() {},
      getData() {
        this.$store
          .dispatch(QUERY, {
            url: "data",
          })
          .then((data) => {
            this.categorys = data.supplierCategory;
  
            console.log(this.othercats);
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.timerLoading = false;
          });
      },
      updateProfileImage(param) {
        console.log(param);
        const _this = this;
        _this.imageLoading = true;
        let fileExtention = param?.type.split("/");
        fileExtention = fileExtention[0];
        if (fileExtention != "image") {
          _this.$store.commit(SET_ERROR, [{ model: true, message: "Only Image required" }]);
          return false;
        }
        _this
          .uploadAttachment(param)
          .then((response) => {
            _this.supplier.profile_logo = head(response);
            _this.supplier.image = this.supplier.profile_logo.id;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.imageLoading = false;
          });
      },
  
      fileInput() {
        const { fileInput } = this.$refs;
        if (fileInput && fileInput.$refs) {
          const { input } = fileInput.$refs;
          if (input) {
            input.click();
          }
        }
      },
      removeInput(index) {
        this.supplier.category_input.splice(index, 1);
      },
      addInput() {
        this.supplier.category_input.push({
          label: "",
          value: "",
        });
      },
      pageTitle() {
        if (this.supplierId) {
          return "Update Supplier";
        }
        return "Create Salary";
      },
      // async updateOrCreate() {
      //   const _this = this;
  
      //   if (!_this.$refs.supplierForm.validate()) {
      //     return false;
      //   }
  
      //   try {
      //     _this.pageLoading = true;
      //     if (_this.supplierId) {
      //       await UPDATE_SUPPLIER(_this.supplierId, _this.supplier).then((response) => {
      //         if (response.supplier && response.supplier.id) {
      //           _this.$router.replace({
      //             name: "supplier",
      //           });
      //         }
      //         _this.$store.commit(SET_MESSAGE, [
      //           { model: true, message: "Supplier Updated Successfully." },
      //         ]);
      //       });
      //     } else {
      //       const supplier = await CREATE_SUPPLIER(_this.supplier);
  
      //       _this.$router.replace({
      //         name: "supplier-detail",
      //         params: { id: supplier.id },
      //         query: { t: new Date().getTime() },
      //       });
      //       _this.$store.commit(SET_MESSAGE, [
      //         { model: true, message: "Supplier Created Successfully." },
      //       ]);
      //     }
      //   } catch (error) {
      //     _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      //   } finally {
      //     _this.pageLoading = false;
      //   }
      // },
  
      async updateOrCreate() {
        const _this = this;
        {
          try {
           
            _this.pageLoading = true;
  
            if (!this.salary.salary_month) {
          this.$store.commit(SET_ERROR, [
            { model: true, message: "The Month field is required" },
          ]);
          return false;
        }
        if (!this.salary.salary_year  ) {
          this.$store.commit(SET_ERROR, [
            { model: true, message: "The Year field is required" },
          ]);
          return false;
        }
            if (_this.$route.name == "salary-update") {
   
              await UPDATE_SALARY(_this.$route.params.id, _this.salary).then(() => {
                _this.$router.replace({
                name: "salary",
                
                query: { t: new Date().getTime() },
              });
                _this.$store.commit(SET_MESSAGE, [
                  { model: true, message: "Salary Updated Successfully." },
                ]);
              });
            } else {
            
              console.log(2222);
              const supplier = await CREATE_SALARY(_this.salary);
  
              _this.$router.replace({
                name: "salary",
                params: { id: supplier.id },
                query: { t: new Date().getTime() },
              });
              _this.$store.commit(SET_MESSAGE, [
                { model: true, message: "Salary Created Successfully." },
              ]);
            }
          } catch (error) {
            _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
          } finally {
            _this.pageLoading = false;
          }
        }
  
        // if (_this.stepper.value == 3 && !_this.$refs.secondStepValid.validate()) {
        //   return false;
        // }
      },
      updateContactPerson(param) {
        this.supplier.contactPerson = param;
      },
      updateAddressPerson(param) {
        this.supplier.address = param;
      },
  
      async getSalary() {
        this.salaryId = this.$route.params.id;
  
        const salary = await GET_SALARY(this.salaryId);
        this.salary = salary;
        this.salary.salary_month = Number(salary.month)
        this.salary.salary_year = Number(salary.year)
        this.singleemployee = salary.employee;
        let selMonth = this.salary.salary_month;
        let monthNumber = String(selMonth).padStart(2, "0"); // Ensure two-digit format
        this.monthName = moment(monthNumber, "MM").format("MMMM"); // Get full month name
        this.singleemployee.loan.loan_rel[this.salary.loan_key].paid_amount =  this.salary?.paid_amount

        
      },
      addContactPerson() {
        this.dialog = true;
      },
    },
    computed: {
      gross_earnings() {
        const total =
        Number(this.salary.basic_salary *this.salary.qty) +
          Number((this.salary.paid_leave) ? (this.salary.paid_leave) : 0) +
          Number((this.salary.incentive) ? (this.salary.incentive) : 0) +
          Number((this.salary.overtime) ? (this.salary.overtime) : 0) 
        console.log(total, "total");
  
        return total;
      },
      gross_deductions() {
        const total =
          Number(this.salary.epf) +
          Number((this.salary.socso) ? (this.salary.socso) : 0) +
          Number((this.salary.sip) ? (this.salary.sip) : 0) +
          Number((this.salary.machine) ? (this.salary.machine) : this.singleemployee.machine) +
          Number((this.salary.advance) ? (this.salary.advance) : 0) +
          Number((this.salary.deduction) ? (this.salary.deduction) : 0) +
          Number((this.salary.unpaid) ? (this.salary.unpaid) : 0) 
  
        console.log(total, "total");
  
        return total;
      },
      getProfileImage() {
        console.log(this.supplier, "supplierData");
        return isEmpty(this.supplier.profile_logo) === false
          ? this.supplier.profile_logo.file.url
          : process.env.VUE_APP_BASE_URL + "media/misc/no_photo_found.png";
      },
    },
    mounted() {
      this.getData();
       this.getEmployee();
      //this.findEmployee();
      this.yearlist();
   
  
      this.$store.dispatch(SET_BREADCRUMB, [
        { text: "Supplier", disabled: true },
        { text: "Create", disabled: true },
      ]);
      // if (this.$refs.supplierForm.validate()) {
      //     return false;
      //   }
      const { name, params } = this.$route;
      if (name === "salaryDetail") {
        this.getSalary();
        const { id } = params;
    //  alert(id)
        if (id) {
          this.customerId = toSafeInteger(id);
          // this.getSalary();
        } else {
          this.goBack();
          // this.findEmployee();
  
        }
      }else{
        this.findEmployee();
  
      }
    },
  };
  </script>
  
  <style scoped>
  .v-image.v-responsive.white.lighten-2.theme--light {
    height: 272px;
  }
  .table th,
  .table td {
    padding: 4px 8px !important;
    vertical-align: center !important;
    /* border-top: 1px solid #ebedf3; */
  }
  .table thead tr {
    background-color: #dbd4d4;
  }
  .table.auto-data-table thead th {
    background-color: rgb(245, 245, 245);
  }
  .table.auto-data-table tbody td {
    height: 40px;
  }
  .table.auto-data-table {
    margin-bottom: 0px;
  }
  .table tbody tr:nth-child(even) {
    background-color: #eff7fd !important;
  }
  .table tbody tr:hover {
    background-color: #eff7fd;
  }
  /* .table tbody td {
    height: 44px;
  } */
  span.salary-select-employee > .v-text-field--outlined fieldset {
    border: unset !important;
  }
  .btx-label {
    font-size: 13px;
  }
  .table thead th label span {
    font-size: 13px;
  }
  .table .v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: 26px !important;
  }
  .table .v-input input {
    max-height: 26px !important;
  }
  
  </style>